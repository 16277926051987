import React, { useState, useEffect, useContext } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dropdown, Space, Radio, Tag, Modal } from 'antd-mobile'
import { post } from 'util/http';
import AuthUtil from 'util/auth'
import module from 'util/module';
import regionData from 'util/region';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import CascaderTreeView from 'component/CascaderTreeView'
import StringTag from 'component/StringTag'
import NewBtns from 'pages/new-btns';
import moment from 'moment'
import { LoginContext } from 'util/context';
import { getMetaLabel } from 'util/meta';

import './detail';
import './form';

function Page() {


    const navigate = useNavigate();

    const hasLogin = useContext(LoginContext);

    const [param, setParam] = useState({
        activeKey: null,
        region: [],
        regionTitle: "地区",
        projectType: [],
        projectTypeTitle: "工程类别",
        hasMore: true,
        current: 0,
        size: 10
    })

    const statusColor = {
        ongoing: 'success',
        approvaling: 'default',
        reject: 'danger',
        finish: 'default'
    }

    const [projectParam, setProjectParam] = useState({
        metaCode: "paifuProject",
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }],
        params: [{
            attrCode: "status",
            operator: "in",
            value: "ongoing,finish"
        }]
    });
    const [projectData, setProjectData] = useState({
        records: []
    });

    const reloadProject = (newParam) => {
        const searchParams = [];
        searchParams.push({
            attrCode: "status",
            operator: "in",
            value: "ongoing,finish"
        });
        if (newParam.region.length > 0) {
            searchParams.push({
                attrCode: "fullRegionCode",
                operator: "like",
                value: newParam.region.join(",") + "%"
            });
        }
        if (newParam.projectType.length > 0) {
            searchParams.push({
                attrCode: "fullTypeCode",
                operator: "like",
                value: newParam.projectType.join(",") + "%"
            });
        }
        setProjectParam({
            ...projectParam, params: searchParams
        });
    }

    const orderProject = (order) => {
        const orders = order == "default" ? [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }] : [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "collectCount",
            order: "desc"
        }];
        setProjectParam({
            ...projectParam, orders: orders
        });
    }


    const confirmCall = (id) => {
        if (hasLogin) {
            post('/api/hr/paifu/my/project/creator/call', {
                id: id
            }).then(res => {
                if (res.status == -1) {
                    Modal.confirm({
                        content: '您当前的积分不足,是否前往获取积分',
                        okText: '获取积分',
                        cancelText: '取消',
                        onConfirm: function () {
                            navigate("/personal/points/obtain");
                        }
                    });

                } else {
                    window.location.href = "tel://" + res.data;
                }
            })
        } else {
            AuthUtil.goLogin("/project");
        }
    }
    const callProjectCreator = (id) => {
        Modal.confirm({
            content: '仅需消耗10积分查看电话,确认？',
            okText: '确认',
            cancelText: '取消',
            onConfirm: function () {
                confirmCall(id);
            }
        });
    }

    useEffect(() => {


    }, [])

    return (
        <>
            <div className="dropdown-head">
                <Dropdown activeKey={param.activeKey} onChange={(key) => {
                    setParam({ ...param, activeKey: key })
                }}>
                    <Dropdown.Item key='region' title={param.regionTitle}>
                        <CascaderTreeView
                            style={{ '--height': '320px' }}
                            initData={regionData}
                            url={module.app + '/public/region/tree'}
                            value={param.region}
                            onChange={(val, extend) => {
                                var regionTitle = (val.length > 0) ? extend.items[extend.items.length - 1].label : "地区";
                                const newParam = { ...param, region: val, regionTitle: regionTitle };
                                if (extend.isLeaf) {
                                    newParam.activeKey = null;
                                    setParam(newParam);
                                    reloadProject(newParam);
                                } else {
                                    setParam(newParam);
                                }
                            }}
                            onConfirm={() => {
                                const newParam = { ...param, activeKey: null };
                                setParam(newParam)
                                reloadProject(newParam);
                            }}
                            onCancel={() => {
                                const newParam = { ...param, region: [], regionTitle: "地区", activeKey: null };
                                setParam(newParam);
                                reloadProject(newParam);
                            }}
                        />
                    </Dropdown.Item>
                    <Dropdown.Item key='projectType' title={param.projectTypeTitle}>
                        <CascaderTreeView
                            style={{ '--height': '320px' }}
                            url={module.app + '/public/meta/data/tree'}
                            param={{
                                metaCode: "paifuProjectType",
                                showRoot: false
                            }}
                            value={param.projectType}
                            onChange={(val, extend) => {
                                var projectTypeTitle = (val.length > 0) ? extend.items[extend.items.length - 1].label : "工程类别";
                                var newParam = { ...param, projectType: val, projectTypeTitle: projectTypeTitle };
                                if (extend.isLeaf) {
                                    newParam.activeKey = null;
                                    setParam(newParam);
                                    reloadProject(newParam);
                                } else {
                                    setParam(newParam);
                                }
                            }}
                            onConfirm={() => {
                                const newParam = { ...param, activeKey: null };
                                setParam(newParam)
                                reloadProject(newParam);
                            }}
                            onCancel={() => {
                                const newParam = { ...param, projectType: [], projectTypeTitle: "工程类别", activeKey: null };
                                setParam(newParam);
                                reloadProject(newParam);
                            }}
                        />
                    </Dropdown.Item>
                    <Dropdown.Item key='more' title='更多筛选'>
                        <div style={{ padding: 12 }}>
                            <Radio.Group defaultValue='default'
                                onChange={(v) => {
                                    const newParam = { ...param, activeKey: null };
                                    setParam(newParam)
                                    orderProject(v);
                                }}>
                                <Space direction='vertical' block>
                                    <Radio block value='default' >
                                        <span className='font-14'>最新发布</span>
                                    </Radio>
                                    {/* <Radio block value='nearest'>
                                        <span className='font-14'>距离最近</span>
                                    </Radio> */}
                                    <Radio block value='topCollect'>
                                        <span className='font-14'>收藏最多</span>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </Dropdown.Item>
                </Dropdown>

            </div>


            <ScrollList className="dropdown-body project-list"
                url={module.app + '/public/meta/data/search'}
                param={projectParam}
                setParam={setProjectParam}
                data={projectData}
                setData={setProjectData}
            >
                {
                    projectData.records.map(project => (
                        <List.Item
                            arrow={false}
                            key={project.id}
                            onClick={() => {
                                navigate("/project/detail?id=" + project.id);
                            }}
                        >
                            {project.showOrder == 2147483647 && <img src={require('../../asserts/images/zd.png')} className="zd" />}
                            <div >
                                <Tag style={{ float: 'right' }} color={statusColor[project.status]}>{getMetaLabel('PROJECT_STATUS', project.status)}</Tag>
                                <Ellipsis direction='end' rows={2} content={project.title} style={{ fontWeight: 'bold' }} />
                            </div>
                            <div>
                                <StringTag data={project.tags}
                                    // style={{ backgroundColor: "#b4b3b3", borderColor: "#b4b3b3" }}
                                    color={"warning"}
                                >

                                </StringTag>
                            </div>
                            <div className="flex-row-center flex-justify-between font-12 mt-10">
                                <span className='flex-row-center'><LocationFill />
                                    <Ellipsis direction='end' content={project.fullRegionName}></Ellipsis>
                                </span>
                                <div>
                                    <span>{moment(project.createTime).format('MM-DD HH:mm')}</span>
                                    {project.status != 'finish' && <Button size='mini' color='primary' style={{ marginLeft: 5 }} onClick={(e) => {
                                        callProjectCreator(project.id);
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                    }}>
                                        <PhoneFill /> 联系老板
                                    </Button>}
                                </div>
                            </div>
                        </List.Item>
                    ))
                }

            </ScrollList>
            <NewBtns />
        </>
    );
}
addPage("/project", Page);
addPage("*", Page);
export default Page;
