import React, { useState } from 'react'
import { addPage } from 'component/DynamicRotes';
import { NavBar, Space, Toast } from 'antd-mobile'

function Page() {

    const back = () =>
        Toast.show({
            content: '点击了返回区域',
            duration: 1000,
        })

    return (
        <>
            <NavBar onBack={back}>标题</NavBar>
        </>
    );
}
addPage("/detail", Page);
export default Page;
