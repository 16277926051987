

import React, { useEffect, useState } from 'react'
import { Cascader, Button, CascaderView, Space } from 'antd-mobile'
import module from 'util/module';
import { post } from 'util/http';

export const Page = ({
    url,
    param = { showRoot: false },
    value,
    initData,
    onChange,
    onConfirm,
    onCancel,
    ...nextProps
}) => {

    const [data, setData] = useState([]);

    const formatData = (data) => {
        const newData = {
            value: data.code,
            label: data.name
        };
        if (data.children != null && data.children.length > 0) {
            newData.children = [];
            data.children.map((item) => {
                newData.children.push(formatData(item));
            })
        }
        return newData;
    }


    useEffect(() => {
        if (initData == null) {
            post(url, param).then(res => {
                var newData = [];
                res.data.map((item) => {
                    newData.push(formatData(item));
                })
                setData(newData);
            })
        } else {
            var newData = [];
            initData.map((item) => {
                newData.push(formatData(item));
            })
            setData(newData);
        }

    }, [])

    return (
        <>
            <CascaderView
                options={data}
                value={value}
                onChange={onChange}
                {...nextProps}
            />
            <div style={{ padding: 20 }}>
                <Button size='small' color='default' onClick={onCancel} style={{ width: "50%" }} >清空选择</Button>
                <Button size='small' color='primary' onClick={onConfirm} style={{ width: "50%" }}>确认选择</Button>
            </div>
        </>
    );
}

export default Page;