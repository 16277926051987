import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dialog, Tabs, NavBar, Tag } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import CascaderTreeView from 'component/CascaderTreeView'
import NewBtns from 'pages/new-btns';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';

function Page() {

    const navigate = useNavigate();


    const [projectParam, setProjectParam] = useState({
        metaCode: "paifuCollection"
    });
    const [projectData, setProjectData] = useState({
        records: []
    });


    useEffect(() => {

    }, [])

    const viewDetail = (item) => {
        if (item.type == 'project') {
            navigate('/project/detail?id=' + item.targetCode);
        } else if (item.type == 'worker') {
            navigate('/worker/detail?id=' + item.targetCode);
        } else if (item.type == 'information') {
            navigate('/information/detail?id=' + item.targetCode);
        }
    }

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>我的收藏</NavBar>

            <ScrollList className=""
                url={module.app + '/my/meta/data/search'}
                param={projectParam}
                setParam={setProjectParam}
                data={projectData}
                setData={setProjectData}
            >
                {
                    projectData.records.map(project => (
                        <List.Item
                            key={project.id}
                        >
                            <div onClick={() => {
                                viewDetail(project);
                            }}>
                                <Ellipsis direction='end' rows={2} content={project.targetTitle} />
                            </div>
                            <div className="flex-row-center flex-justify-between font-12 mt-10">
                                <span className='flex-row-center'>
                                    <Tag >{getMetaLabel('COLLECTION_TYPE', project.type)}</Tag>
                                    <span className='ml-10'>{moment(project.createTime).format('YYYY-MM-DD HH:mm')}</span>
                                </span>
                                <div>
                                    <Button size='mini' color='success' style={{ marginLeft: 5 }} onClick={() => { viewDetail(project); }}>
                                        查看
                                    </Button>
                                </div>
                            </div>
                        </List.Item>
                    ))
                }

            </ScrollList>
        </>
    );
}
addPage("/personal/collection", Page);
export default Page;
