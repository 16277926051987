import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import CascaderSelect from 'component/CascaderSelect';
import { post } from 'util/http';
import module from 'util/module';
import regionData from 'util/region';
import { NavBar, Dialog, Toast, Image, Button, Tag, Form, Input, TextArea } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Page() {


    let query = useQuery();
    const metaCode = "paifuProject";

    const [pageForm] = Form.useForm()



    const navigate = useNavigate();

    const [param, setParam] = useState({
        step: 1
    })


    const nextStep = () => {
        pageForm.validateFields().then((data) => {
            const content = data.content;
            if (content.length > 25) {
                pageForm.setFieldValue("title", content.substring(0, 25) + "...");
            } else {
                pageForm.setFieldValue("title", content);
            }
            setParam({ ...param, step: 2 })
        })
    }
    const preStep = () => {
        setParam({ ...param, step: 1 })
    }
    const submitStep = () => {
        Dialog.confirm({
            content: '重新发布需要重新审批，确认发布？',
            onConfirm: () => {
                pageForm.validateFields().then((data) => {
                    const url = module.app + '/my/meta/data/save';
                    data.typeCodeSelect = null;
                    data.regionCodeSelect = null;
                    data.status = "ongoing";
                    post(url, {
                        metaCode: metaCode,
                        datas: data
                    }).then(res => {
                        Toast.show({
                            content: '发布成功',
                        })
                        navigate("/personal/project");
                    })
                })
            }
        })

    }

    useEffect(() => {
        post(module.app + '/public/meta/data/get', {
            metaCode: "paifuProject",
            id: query.get("id")
        }).then(res => {
            const newData = res.data;
            newData.typeCodeSelect = newData.fullTypeCode.split(",");
            newData.regionCodeSelect = newData.fullRegionCode.split(",");
            pageForm.setFieldsValue(newData);
        })
    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>修改工程</NavBar>
            <Form
                form={pageForm}
                // layout='horizontal'
                footer={
                    <>
                        <Button block type='submit' color='primary' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='typeCodeSelect' label="工程类别"
                        rules={[{ required: true, message: '请选择工程类别' }]}
                    >
                        <CascaderSelect url={module.app + '/public/meta/data/tree'} param={{
                            metaCode: "paifuProjectType"
                        }} onChange={(v, e, extend) => {
                            pageForm.setFieldValue("typeName", extend.label);
                            pageForm.setFieldValue("typeCode", extend.value);
                            pageForm.setFieldValue("fullTypeName", extend.fullLabel.join(","));
                            pageForm.setFieldValue("fullTypeCode", extend.fullValue.join(","));
                        }} />
                    </Form.Item>
                    <Form.Item name='regionCodeSelect' label='地区'
                        rules={[{ required: true, message: '请选择地区' }]}>
                        <CascaderSelect
                            initData={regionData}
                            url={module.app + '/public/region/tree'}
                            onChange={(v, e, extend) => {
                                pageForm.setFieldValue("regionName", extend.label);
                                pageForm.setFieldValue("regionCode", extend.value);
                                pageForm.setFieldValue("fullRegionName", extend.fullLabel.join(""));
                                pageForm.setFieldValue("fullRegionCode", extend.fullValue.join(","));
                            }} />
                    </Form.Item>
                    <Form.Item name='title' label="工程标题"
                        rules={[{ required: true, message: '请输入工程标题' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='content' label="工程内容">
                        <TextArea
                            placeholder='请输入或者黏贴工程信息'
                            maxLength={500}
                            rows={4}
                            showCount
                        />
                    </Form.Item>
                    <Form.Item name='tags' label="标签" >
                        <Input placeholder='多个标签用空格分割' />
                    </Form.Item>

                    <Form.Item name='regionCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='regionName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullRegionCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullRegionName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='typeName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='typeCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullTypeName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullTypeCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                </>
            </Form>
        </>
    );
}
addRoute("/personal/project/form", Page);
export default Page;
