import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import CascaderSelect from 'component/CascaderSelect';
import { post } from 'util/http';
import module from 'util/module';
import regionData from 'util/region';
import { NavBar, Space, Toast, Image, Button, Tag, Form, Input, TextArea } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {


    const metaCode = "paifuProject";

    const [pageForm] = Form.useForm()



    const navigate = useNavigate();

    const [param, setParam] = useState({
        step: 2
    })


    const nextStep = () => {
        pageForm.validateFields().then((data) => {
            const content = data.content;
            if (content.length > 25) {
                pageForm.setFieldValue("title", content.substring(0, 25) + "...");
            } else {
                pageForm.setFieldValue("title", content);
            }
            setParam({ ...param, step: 2 })
        })
    }
    const preStep = () => {
        setParam({ ...param, step: 1 })
    }
    const submitStep = () => {
        pageForm.validateFields().then((data) => {
            const url = module.app + '/my/meta/data/save';
            data.typeCodeSelect = null;
            data.regionCodeSelect = null;

            post(url, {
                metaCode: metaCode,
                datas: data
            }).then(res => {
                Toast.show({
                    content: '您的工程发布成功，请等待审核！',
                })
                navigate("/personal/project");
            })
        })
    }

    useEffect(() => {
        post(module.ac + "/my/account/info", {}).then(res => {
            pageForm.setFieldValue("creatorPhone", res.data.phone);
            pageForm.setFieldValue("creatorHeadImg", res.data.attributes.headimg);
        })
    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>发布工程</NavBar>
            <Form
                form={pageForm}
                // layout='horizontal'
                footer={
                    <>
                        {param.step == 1 && <Button block type='submit' color='primary' size='middle' onClick={nextStep}>
                            下一步
                        </Button>}
                        {/* {param.step == 2 && <Button block type='submit' color='default' size='middle' onClick={preStep}>
                            上一步
                        </Button>} */}
                        {param.step == 2 && <Button block type='submit' color='primary' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>}
                    </>
                }
            >
                {param.step == 1 &&
                    <Form.Item name='content' help='详情地址' rules={[{ required: true, message: '请输入工程信息' }]}>
                        <TextArea
                            placeholder='请输入或者黏贴工程信息'
                            maxLength={500}
                            rows={8}
                            showCount
                        />
                    </Form.Item>}


                {param.step == 2 && (<>

                    <Form.Item name='regionCodeSelect' label='地区'
                        rules={[{ required: true, message: '请选择地区' }]}>
                        <CascaderSelect

                            initData={regionData}
                            url={module.app + '/public/region/tree'}
                            onChange={(v, e, extend) => {
                                pageForm.setFieldValue("regionName", extend.label);
                                pageForm.setFieldValue("regionCode", extend.value);
                                pageForm.setFieldValue("fullRegionName", extend.fullLabel.join(""));
                                pageForm.setFieldValue("fullRegionCode", extend.fullValue.join(","));
                            }} />
                    </Form.Item>
                    <Form.Item name='typeCodeSelect' label="工程类别"
                        rules={[{ required: true, message: '请选择工程类别' }]}
                    >
                        <CascaderSelect
                            label="选择类别"
                            url={module.app + '/public/meta/data/tree'} param={{
                                metaCode: "paifuProjectType"
                            }} onChange={(v, e, extend) => {
                                pageForm.setFieldValue("typeName", extend.label);
                                pageForm.setFieldValue("typeCode", extend.value);
                                pageForm.setFieldValue("fullTypeName", extend.fullLabel.join(","));
                                pageForm.setFieldValue("fullTypeCode", extend.fullValue.join(","));
                            }} />
                    </Form.Item>

                    <Form.Item name='title' label="工程标题"
                        rules={[{ required: true, message: '请输入工程标题' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='creatorPhone' label="联系电话"
                        rules={[{ required: true, message: '请输入联系电话' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='creatorHeadImg' label="头像" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='content' label="工程内容">
                        <TextArea
                            placeholder='请输入或者黏贴工程信息'
                            maxLength={500}
                            rows={4}
                            showCount
                        />
                    </Form.Item>
                    <Form.Item name='tags' label="标签" >
                        <Input placeholder='多个标签用空格分割' />
                    </Form.Item>

                    <Form.Item name='regionCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='regionName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullRegionCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullRegionName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='typeName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='typeCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullTypeName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullTypeCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    {/* <Form.Item name='address' label="详细地址" >
                        <Input />
                    </Form.Item> */}
                </>)}
            </Form>
        </>
    );
}
addRoute("/project/form", Page);
export default Page;
