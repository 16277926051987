import React, { useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Toast, Button, Form, ImageUploader } from 'antd-mobile'
import { useNavigate, useLocation } from 'react-router-dom';

import axios from "axios";


function Page() {

    const [pageForm] = Form.useForm()

    const navigate = useNavigate();
    const submitStep = () => {
        pageForm.validateFields().then((data) => {
            post(module.ac + '/my/account/attr/save', {
                metaCode: 'headimg',
                metaValue: data.headimg[0].url
            }).then(res => {
                Toast.show({
                    content: '更新成功',
                })
                navigate(-1);
            })
        })
    }

    useEffect(() => {
        post(module.ac + '/my/account/info', {
        }).then(res => {
            const newData = {
                headimg: [{
                    url: res.data.attributes.headimg
                }]
            }
            pageForm.setFieldsValue(newData);
        })
    }, [])

    const uploadImg = (file) => {
        var newUploadData = new FormData();
        newUploadData.append("file", file);
        return new Promise((resolve, reject) => {
            const url = module.app + '/resource/v1.0/image/upload';
            axios.post(url, newUploadData).then((res) => {
                return resolve({
                    url: res.data.data
                });
            }, (err) => {
                console.log(err);
                reject && reject(err);
            })
        })
    }

    const beforeUpload = (file) => {
        if (file.size > 1024 * 1024) {
            Toast.show('请选择小于 1M 的图片')
            return null
        }
        return file
    }

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>修改头像</NavBar>
            <Form
                form={pageForm}
                footer={
                    <>
                        <Button block type='submit' color='primary' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name='headimg' label="头像"
                        rules={[{ required: true, message: '请选择头像' }]}>
                        <ImageUploader
                            multiple={false}
                            maxCount={1}
                            upload={uploadImg}
                            beforeUpload={beforeUpload}
                        />
                    </Form.Item>
                </>
            </Form>
        </>
    );
}
addRoute("/personal/edit/headimg", Page);
export default Page;
