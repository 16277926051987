import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dialog, Tabs, NavBar, Tag, Modal } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import CascaderTreeView from 'component/CascaderTreeView'
import NewBtns from 'pages/new-btns';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';

function Page() {

    const navigate = useNavigate();

    const [project, setProject] = useState({});
    const [param, setParam] = useState({
        activeKey: null,
        region: [],
        regionTitle: "地区",
        projectType: [],
        projectTypeTitle: "工程类别",
        hasMore: true,
        current: 0,
        size: 10
    })

    const [projectParam, setProjectParam] = useState({
        metaCode: "paifuProject",
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [projectData, setProjectData] = useState({
        records: []
    });

    const reloadProject = (newParam) => {
        const searchParams = [];
        for (var i in newParam) {
            searchParams.push({
                attrCode: i,
                operator: "=",
                value: newParam[i]
            });
        }
        setProjectParam({
            ...projectParam, params: searchParams
        });
    }

    const finishProject = (id) => {
        Dialog.confirm({
            content: '确认结束招聘',
            onConfirm: () => {
                post('/api/hr/paifu/my/project/finish', {
                    id: id
                }).then(res => {
                    reloadProject({});
                })
            }
        })
    }

    const deleteProject = (id) => {
        Dialog.confirm({
            content: '确认删除工程',
            onConfirm: () => {
                post(module.app + '/my/meta/data/delete', {
                    metaCode: "paifuProject",
                    id: id
                }).then(res => {
                    reloadProject({});
                })
            }
        })
    }

    const modifyProject = (id) => {
        navigate("/personal/project/form?id=" + id);
    }

    const restartProject = (id) => {
        navigate("/personal/project/form?id=" + id);
    }

    const tabs = [
        {
            key: 'all',
            title: '全部',
        },
        {
            key: 'ongoing',
            title: '正在招',
            badge: '5',
        },
        {
            key: 'approvaling',
            title: '审核中',
            badge: '5',
        },
        {
            key: 'reject',
            title: '被下架',
            badge: '99+',
        },
        {
            key: 'finish',
            title: '已招满',
            badge: '99+',
        }
    ]

    const statusColor = {
        ongoing: 'primary',
        approvaling: 'warning',
        reject: 'danger',
        finish: 'success'
    }

    const toppingProject = (id, showOrder) => {
        post('/api/hr/paifu/my/project/topping', {
            id: id,
            showOrder: showOrder
        }, {
            loading: true
        }).then(res => {
            if (res.status == -1) {
                Modal.confirm({
                    content: '您当前的积分不足，是否前往获取积分',
                    okText: '获取积分',
                    cancelText: '取消',
                    onConfirm: function () {
                        navigate("/personal/points/obtain");
                    }
                });
            } else {
                reloadProject({});
            }

        })
    }
    useEffect(() => {

    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>我的工程</NavBar>
            <div className="">
                <Tabs onChange={(key) => {
                    reloadProject(key == 'all' ? {} : {
                        "status": key
                    })
                }}>
                    {tabs.map(item => (
                        <Tabs.Tab title={item.title} key={item.key}>
                        </Tabs.Tab>
                    ))}
                </Tabs>
            </div>
            <ScrollList className=""
                url={module.app + '/my/meta/data/search'}
                param={projectParam}
                setParam={setProjectParam}
                data={projectData}
                setData={setProjectData}
            >
                {
                    projectData.records.map(project => (
                        <List.Item
                            key={project.id}
                        >
                            <div style={{ float: 'right' }}>
                                {project.showOrder == 2147483647 && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => {
                                    toppingProject(project.id, 0);
                                }}>取消置顶
                                </Button>}
                                {project.showOrder == 0 && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => {
                                    toppingProject(project.id, 2147483647);
                                }}>置顶
                                </Button>}
                            </div>
                            <div onClick={() => {
                                navigate("/project/detail?id=" + project.id);
                            }}>
                                <Ellipsis direction='end' rows={2} content={project.title} />
                            </div>
                            <div className="flex-row-center flex-justify-between font-12 mt-10">

                                <span className='flex-row-center'>
                                    <Tag color={statusColor[project.status]}>{getMetaLabel('PROJECT_STATUS', project.status)}</Tag>
                                    <span className='ml-10'>{moment(project.createTime).format('YYYY-MM-DD HH:mm')}</span>
                                </span>
                                <div>
                                    {/* {project.status == 'finish' && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { restartProject(project.id) }}>
                                        重新招聘
                                    </Button>} */}
                                    {/* {project.status == 'ongoing' && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { finishProject(project.id) }}>
                                        结束
                                    </Button>} */}

                                    {(project.status == 'reject' || project.status == 'ongoing') && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { modifyProject(project.id) }}>
                                        修改
                                    </Button>}
                                    <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { deleteProject(project.id) }}>
                                        删除
                                    </Button>
                                </div>
                            </div>
                        </List.Item>
                    ))
                }

            </ScrollList>
        </>
    );
}
addPage("/personal/project", Page);
export default Page;
