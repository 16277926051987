

import React, { useState } from 'react'
import { Tag, Space } from 'antd-mobile'

const Page = ({
    data = "",
    sep = " ",
    ...nextProps
}) => {
    const [tags, setTags] = useState([]);

    return (
        <Space>
            {data.split(sep).map(item => {
                if (item != null && item != '') {
                    return (

                        <Tag key={item}  {...nextProps}>{item}</Tag>
                    )
                }
            })}
        </Space>
    );
}

export default Page;