import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dialog, Modal, NavBar, Tag } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import { getMetaLabel } from 'util/meta';

function Page() {

    const metaCode = "paifuWorker";

    const navigate = useNavigate();

    const [param, setParam] = useState({
        metaCode: metaCode
    });

    const [listData, setListData] = useState({
        records: []
    });

    const reload = (newParam) => {
        const searchParams = [];
        for (var i in newParam) {
            searchParams.push({
                attrCode: i,
                operator: "=",
                value: newParam[i]
            });
        }
        setParam({
            ...param, params: searchParams
        });
    }

    const finishResume = (id) => {
        Dialog.confirm({
            content: '确认结束找工作',
            onConfirm: () => {
                post('/api/hr/paifu/worker/v1.0/finish', {
                    id: id
                }).then(res => {
                    reload({});
                })
            }
        })
    }

    const deleteResume = (id) => {
        Dialog.confirm({
            content: '确认删除简历',
            onConfirm: () => {
                post(module.app + '/my/meta/data/delete', {
                    metaCode: "paifuWorker",
                    id: id
                }).then(res => {
                    reload({});
                })
            }
        })
    }

    const modifyResume = (id) => {
        navigate("/personal/resume/form?id=" + id);
    }

    const restartProject = (id) => {
        navigate("/personal/resume/form?id=" + id);
    }



    const statusColor = {
        ongoing: 'primary',
        approvaling: 'warning',
        reject: 'danger',
        finish: 'success'
    }

    const toppingWorker = (id, showOrder) => {
        post('/api/hr/paifu/my/worker/topping', {
            id: id,
            showOrder: showOrder
        }, {
            loading: true
        }).then(res => {
            if (res.status == -1) {
                Modal.confirm({
                    content: '您当前的积分不足，是否前往获取积分',
                    okText: '获取积分',
                    cancelText: '取消',
                    onConfirm: function () {
                        navigate("/personal/points/obtain");
                    }
                });
            } else {
                reload({});
            }

        })
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>我的简历</NavBar>


            <ScrollList className=""
                url={module.app + '/my/meta/data/search'}
                param={param}
                setParam={setParam}
                data={listData}
                setData={setListData}
            >
                {
                    listData.records.map(project => (
                        <List.Item
                            key={project.id}
                        >
                            <div style={{ float: 'right' }}>
                                {project.showOrder == 2147483647 && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => {
                                    toppingWorker(project.id, 0);
                                }}>取消置顶
                                </Button>}
                                {project.showOrder == 0 && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => {
                                    toppingWorker(project.id, 2147483647);
                                }}>置顶
                                </Button>}
                            </div>
                            <div onClick={() => {
                                navigate("/worker/detail?id=" + project.id);
                            }}>
                                <Ellipsis direction='end' rows={2} content={project.fullTypeName} />
                            </div>
                            <div className="flex-row-center flex-justify-between font-12 mt-10">
                                <span className='flex-row-center'>
                                    <Tag color={statusColor[project.status]}>{getMetaLabel('WORKER_STATUS', project.status)}</Tag>
                                    <span className='ml-10'>{moment(project.createTime).format('YYYY-MM-DD HH:mm')}</span>
                                </span>
                                <div>
                                    {/* {project.status == 'finish' && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { restartProject(project.id) }}>
                                        重新发布
                                    </Button>}
                                    {project.status == 'ongoing' && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { finishResume(project.id) }}>
                                        结束
                                    </Button>} */}

                                    {(project.status == 'reject' || project.status == 'ongoing') && <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { modifyResume(project.id) }}>
                                        修改
                                    </Button>}
                                    <Button size='mini' color='default' style={{ marginLeft: 5 }} onClick={() => { deleteResume(project.id) }}>
                                        删除
                                    </Button>
                                </div>
                            </div>
                        </List.Item>
                    ))
                }

            </ScrollList>

            <div className='pd-20'>
                <Button block color='primary' onClick={() => { navigate("/worker/form"); }} >发布新简历</Button>
            </div>
        </>
    );
}
addPage("/personal/resume", Page);
export default Page;
