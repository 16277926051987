import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dialog, Tabs, NavBar, Tag, Space } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import CascaderTreeView from 'component/CascaderTreeView'
import NewBtns from 'pages/new-btns';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';

function Page() {

    const navigate = useNavigate();


    const [projectParam, setProjectParam] = useState({
        // metaCode: "paifuCollection"
    });
    const [projectData, setProjectData] = useState({
        records: []
    });

    const [member, setMember] = useState({})


    useEffect(() => {
        post('/api/crm/my/info', {
        }).then(res => {
            setMember(res.data);
        })
    }, [])

    const viewDetail = (item) => {
        if (item.type == 'project') {
            navigate('/project/detail?id=' + item.targetCode);
        } else if (item.type == 'worker') {
            navigate('/worker/detail?id=' + item.targetCode);
        } else if (item.type == 'information') {
            navigate('/information/detail?id=' + item.targetCode);
        }
    }

    return (
        <div className='personal-points'>
            <NavBar onBack={() => { navigate(-1) }}>积分记录</NavBar>

            <div className='p1'>
                剩余积分：{member.points}
            </div>
            <ScrollList className=""
                url={'/api/crm/my/points/search'}
                param={projectParam}
                setParam={setProjectParam}
                data={projectData}
                setData={setProjectData}
            >
                {
                    projectData.records.map(project => (
                        <List.Item
                            key={project.id}
                        >
                            <div className="flex-row-center flex-justify-between font-12 mt-10">
                                <Space>
                                    <Tag >{project.points}积分</Tag>
                                    {project.ruleName}
                                </Space>
                                <span className='flex-row-center'>
                                    <span className='ml-10'>{moment(project.createTime).format('YYYY-MM-DD HH:mm')}</span>

                                </span>
                            </div>
                        </List.Item>
                    ))
                }

            </ScrollList>
        </div>
    );
}
addPage("/personal/points", Page);
export default Page;
