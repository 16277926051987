import React, { useState, useEffect, useContext } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import StringTag from 'component/StringTag';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Space, Toast, Image, Button, Tag } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { HeartOutline, HeartFill, SendOutline } from 'antd-mobile-icons'
import moment from 'moment'
import { LoginContext } from 'util/context';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {

    const navigate = useNavigate();
    let query = useQuery();
    const [collectId, setCollectId] = useState(null);
    const hasLogin = useContext(LoginContext);
    const [project, setProject] = useState({});

    useEffect(() => {

        post(module.app + '/public/meta/data/get', {
            metaCode: "paifuInformation",
            id: query.get("id")
        }).then(res => {
            setProject(res.data);
        })

        if (hasLogin) {
            post(module.app + '/my/meta/data/search', {
                metaCode: "paifuCollection",
                params: [{
                    attrCode: "targetCode",
                    operator: "=",
                    value: query.get("id")
                }, {
                    attrCode: "type",
                    operator: "=",
                    value: 'information'
                }]
            }).then(res => {
                if (res.data.total > 0) {
                    setCollectId(res.data.records[0].id)
                }
            })
        }

    }, [])

    const back = () => {
        navigate("/information");
    }

    const cancelCollect = () => {
        const url = module.app + '/my/meta/data/delete';
        post(url, {
            metaCode: "paifuCollection",
            id: collectId
        }).then(res => {
            Toast.show({
                content: '取消成功',
            })
            setCollectId(null);
        })
    }
    const collectProject = () => {
        const url = module.app + '/my/meta/data/save';
        post(url, {
            metaCode: "paifuCollection",
            datas: {
                type: "information",
                targetCode: query.get("id"),
                targetTitle: project.title
            }
        }).then(res => {
            Toast.show({
                content: '收藏成功',
            })
            setCollectId(res.data);
        })
    }



    return (
        <>
            <NavBar onBack={back}>资讯详情</NavBar>
            <div className="pd-10-20">
                <div className="font-16">{project.title}</div>
                <div className="font-12 c-gray pd-tb-15" >
                    {moment(project.createTime).format('YYYY-MM-DD HH:mm')}
                    <span className="ml-10">{project.creatorName}</span>
                </div>
                <Tag>{project.typeName}</Tag>
                <div className="font-14 pd-tb-20">资讯详情</div>
                <div className="lh-26">
                    <div dangerouslySetInnerHTML={{
                        __html: project.content
                    }}
                    />
                </div>
            </div>

            <div className="fixed-bottom ">
                <div className="flex-row-center flex-justify-between pd-10">
                    {collectId == null && <span className='font-18 ml-10'
                        onClick={collectProject}><HeartOutline /> 收藏</span>}
                    {collectId != null && <span className='font-18 ml-10 color-red'
                        onClick={cancelCollect}><HeartFill /> 收藏</span>}
                    <span className='font-18 mr-10'><SendOutline /> 分享</span>
                </div>
            </div>
        </>
    );
}
addRoute("/information/detail", Page);
export default Page;
