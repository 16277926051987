import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dropdown, Card, Toast, Badge, Grid, Modal } from 'antd-mobile'

import { post } from 'util/http';
import module from 'util/module';

import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import CascaderTreeView from 'component/CascaderTreeView'
import NewBtns from 'pages/new-btns';
import moment from 'moment'

import {
    AppOutline,
    MessageFill,
    UnorderedListOutline,
    AddSquareOutline,
    AppstoreOutline,
    UserOutline,
    FileOutline,
    PhonebookOutline,
    PayCircleOutline,
    CollectMoneyOutline,
    ClockCircleOutline,
    SystemQRcodeOutline,
    MessageOutline,
    AaOutline,
    FaceRecognitionOutline
} from 'antd-mobile-icons'

import './project';
import './project-form'
import './collection'
import './edit'
import './edit-name'
import './edit-headimg'
import './edit-phone'
import './resume'
import './resume-form'
import './password-reset'
import './points'
import './points-obtain'
import './points-buy'
import './sign-result'
import './sign-error'
import './feedback-form'

function Page() {


    const navigate = useNavigate();

    const [account, setAccount] = useState({
        attributes: {}
    });

    const [param, setParam] = useState({
        activeKey: null,
        region: [],
        regionTitle: "地区",
        projectType: [],
        projectTypeTitle: "工程类别",
        hasMore: true,
        current: 0,
        size: 10
    })

    const [projectParam, setProjectParam] = useState({
        metaCode: "paifuProject"
    });
    const [projectData, setProjectData] = useState({
        records: []
    });

    const reloadProject = (newParam) => {
        const searchParams = [];
        if (newParam.region.length > 0) {
            searchParams.push({
                attrCode: "fullRegionCode",
                operator: "like",
                value: newParam.region.join(",") + "%"
            });
        }
        if (newParam.projectType.length > 0) {
            searchParams.push({
                attrCode: "fullTypeCode",
                operator: "like",
                value: newParam.projectType.join(",") + "%"
            });
        }
        setProjectParam({
            ...projectParam, params: searchParams
        });
    }


    const tabs = [
        {
            key: '/project/form',
            title: '发布工程',
            icon: <AddSquareOutline className='font-26 mb-5' />,
            badge: Badge.dot,
        },
        {
            key: '/personal/project',
            title: '我的工程',
            icon: <AppstoreOutline className='font-26 mb-5' />,
            badge: '5',
        },
        {
            key: '/personal/collection',
            title: '我的收藏',
            icon: <AppOutline className='font-26 mb-5' />,
            badge: Badge.dot,
        },
        {
            key: '/personal/resume',
            title: '我的简历',
            icon: <FileOutline className='font-26 mb-5' />,
            badge: Badge.dot,
        },
        {
            key: '/personal/points/buy',
            title: '积分充值',
            icon: <PayCircleOutline className='font-26 mb-5' />,
            badge: Badge.dot,
        },
        {
            key: '/personal/points',
            title: '积分记录',
            icon: <CollectMoneyOutline className='font-26 mb-5' />,
            badge: Badge.dot,
        },
        {
            key: '/personal/points/obtain',
            title: '获取积分',
            icon: <AaOutline className='font-26 mb-5' />,
            badge: Badge.dot,
        }, {
            key: '/personal/points/sign',
            title: '每日签到',
            icon: <ClockCircleOutline className='font-26 mb-5' />,
            badge: Badge.dot,
            clickFn: () => {
                post('/api/hr/paifu/my/day/sign', {
                }).then(res => {
                    if (res.data == "success") {
                        navigate("/personal/sign/result?points=" + res.msg);
                    } else {
                        Toast.show(res.msg)
                    }
                })
            }
        }

    ]

    useEffect(() => {
        post(module.ac + '/my/account/info', {
        }).then(res => {
            setAccount(res.data);
        })


        post("/api/weixin/js/config", {
            url: window.location.href
        }).then(res => {
            window.wx.config({
                debug: false,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'showAllNonBaseMenuItem', 'showMenuItems'],
                ...res.data
            })
        })

        window.wx.ready(function () {
            post('/api/crm/my/info', {
            }).then(res => {
                const link = window.location.origin + "/api/weixin/auth/v1.0/auth/login?bind=true&bindUrl=/bind/" + res.data.referralCode;
                window.wx.updateAppMessageShareData({
                    title: '派服-专业地坪信息服务平台', // 分享标题
                    desc: '派服-专业地坪信息服务平台', // 分享描述
                    link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    imgUrl: 'https://static-1306521133.cos.ap-beijing.myqcloud.com/paifu/paifu-console/build/static/media/logo.8a654adb4c209072e47f.png', // 分享图标
                    success: function () {
                    }
                })
            })
        })
    }, [])

    return (
        <div className='pd-10'>
            <div>
                <div className="flex-row-center ">
                    <div style={{ width: 60 }}>
                        <Image
                            src={account.attributes.headimg}
                            style={{ borderRadius: 20 }}
                            fit='cover'
                            width={40}
                            height={40}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className='float-right'>
                            <Button size='mini' onClick={() => { navigate("/personal/edit") }}>修改资料</Button>
                        </div>
                        <div style={{ lineHeight: "35px" }}>
                            <h3 style={{ display: 'inline' }}>{account.name}</h3>
                            <span style={{ marginLeft: '10px' }}>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                            </span>
                        </div>
                        <div>
                            手机号：{account.phone}
                        </div>

                    </div>
                </div>
            </div>
            <div style={{ marginTop: 10 }}>
                <Card title='我的服务' >
                    <Grid columns={4} gap={8}>

                        {tabs.map(item => (
                            <Grid.Item
                                style={{ marginBottom: 15 }}
                                key={item.key} onClick={() => {
                                    if (item.tip) {
                                        Toast.show({
                                            content: '功能待实现！',
                                        })
                                    } else if (item.clickFn != null) {
                                        item.clickFn();
                                    } else {
                                        navigate(item.key);
                                    }
                                }}>
                                <div className='flex-column-center  flex-justify-center'>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </div>
                            </Grid.Item>
                        ))}
                    </Grid>

                </Card>

                <Card title='平台服务' >
                    <Grid columns={4} gap={8}>

                        <Grid.Item onClick={() => {
                            window.location.href = "tel://18322291249";
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <PhonebookOutline className='font-26 mb-5' />
                                <span>客服电话</span>
                            </div>
                        </Grid.Item>
                        <Grid.Item onClick={() => {
                            Modal.show({
                                content: <img src={require('../../asserts/images/qrcode.jpg')} />,
                                closeOnMaskClick: true,
                            })
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <SystemQRcodeOutline className='font-26 mb-5' />
                                <span>二维码</span>
                            </div>
                        </Grid.Item>
                        <Grid.Item onClick={() => {
                            Toast.show({
                                content: '点击右上角，分享给朋友或者朋友圈',
                            })
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <MessageOutline className='font-26 mb-5' />
                                <span>分享</span>
                            </div>
                        </Grid.Item>

                        <Grid.Item onClick={() => {
                            navigate("/personal/feedback/form")
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <FaceRecognitionOutline className='font-26 mb-5' />
                                <span>反馈</span>
                            </div>
                        </Grid.Item>


                    </Grid>

                </Card>
            </div>

        </div>
    );
}
addPage("/personal", Page);
export default Page;
