import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import CascaderSelect from 'component/CascaderSelect';
import { post } from 'util/http';
import module from 'util/module';
import regionData from 'util/region';
import { NavBar, Space, Toast, Image, Button, Dialog, Form, Input, TextArea } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Page() {


    let query = useQuery();
    const metaCode = "paifuWorker";

    const [pageForm] = Form.useForm();
    const [creator, setCreator] = useState({
        attributes: {}
    });


    const navigate = useNavigate();

    const [param, setParam] = useState({
        step: 1
    })


    const nextStep = () => {
        pageForm.validateFields().then((data) => {
            const content = data.content;
            if (content.length > 25) {
                pageForm.setFieldValue("title", content.substring(0, 25) + "...");
            } else {
                pageForm.setFieldValue("title", content);
            }
            setParam({ ...param, step: 2 })
        })
    }
    const preStep = () => {
        setParam({ ...param, step: 1 })
    }
    const submitStep = () => {
        pageForm.validateFields().then((data) => {

            const url = module.app + '/my/meta/data/save';
            data.typeCodeSelect = null;
            data.regionCodeSelect = null;
            data.status = "approvaling";
            data.headImage = creator.attributes.headimg;
            data.gender = creator.attributes.gender;
            data.birthdate = creator.attributes.birthdate;
            data.name = creator.name;
            post(url, {
                metaCode: metaCode,
                datas: data
            }).then(res => {
                Toast.show({
                    content: '简历发布成功，等待审核'
                })
                navigate("/personal/resume");
            })

        })
    }

    useEffect(() => {
        post(module.ac + "/my/account/info", {}).then(res => {
            setCreator(res.data);
            pageForm.setFieldValue("creatorPhone", res.data.phone);
        })

    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>个人简历</NavBar>
            <Form
                form={pageForm}
                // layout='horizontal'
                footer={
                    <>
                        <Button block type='submit' color='primary' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name='regionCodeSelect' label='期望工作地'
                        rules={[{ required: true, message: '请选择地区' }]}>
                        <CascaderSelect
                            initData={regionData}
                            url={module.app + '/public/region/tree'}
                            onChange={(v, e, extend) => {
                                pageForm.setFieldValue("regionName", extend.label);
                                pageForm.setFieldValue("regionCode", extend.value);
                                pageForm.setFieldValue("fullRegionName", extend.fullLabel.join(""));
                                pageForm.setFieldValue("fullRegionCode", extend.fullValue.join(","));
                            }} />
                    </Form.Item>
                    <Form.Item name='typeCodeSelect' label="工种类别"
                        rules={[{ required: true, message: '请选择工程类别' }]}
                    >
                        <CascaderSelect
                            label="选择工种"
                            url={module.app + '/public/meta/data/tree'} param={{
                                metaCode: "paifuWorkType"
                            }} onChange={(v, e, extend) => {
                                pageForm.setFieldValue("typeName", extend.label);
                                pageForm.setFieldValue("typeCode", extend.value);
                                pageForm.setFieldValue("fullTypeName", extend.fullLabel.join(","));
                                pageForm.setFieldValue("fullTypeCode", extend.fullValue.join(","));
                            }} />
                    </Form.Item>
                    {/* <div className='pd-20'>
                        <Button block fill='outline' className="btn-dashed">添加工种类别</Button>
                    </div> */}

                    {/* <div className='pd-20'>
                        <Button block fill='outline' className="btn-dashed">添加工种类别</Button>
                    </div> */}
                    <Form.Item name='salary' label="期望薪资" >
                        <Input placeholder='面议或500元/日' />
                    </Form.Item>
                    <Form.Item name='creatorPhone' label="联系电话"
                        rules={[{ required: true, message: '请输入联系电话' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='intro' label="个人介绍">
                        <TextArea
                            placeholder='请输入个人介绍信息（工作经验）'
                            maxLength={500}
                            rows={4}
                            showCount
                        />
                    </Form.Item>
                    <Form.Item name='tags' label="标签" >
                        <Input placeholder='多个标签用空格分割' />
                    </Form.Item>

                    <Form.Item name='regionCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='regionName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullRegionCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullRegionName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='typeName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='typeCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullTypeName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='fullTypeCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                </>
            </Form>
        </>
    );
}
addRoute("/worker/form", Page);
export default Page;
