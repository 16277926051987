const AuthUtil = {
    isInWeixin: () => {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    },
    goLogin: (targetUrl) => {
        if (AuthUtil.isInWeixin()) {
            if (targetUrl == null) {
                window.location.href = "/api/weixin/auth/v1.0/auth/login?bind=true&bindUrl=/bind"
            } else {
                window.location.href = "/api/weixin/auth/v1.0/auth/login?bind=true&bindUrl=/bind&targetUrl=" + targetUrl;
            }
        } else {
            window.location.href = "/login"
        }
    }
}

export default AuthUtil;