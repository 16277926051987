import React, { useState, useEffect } from 'react'
import { Badge, Button, Space, TabBar } from 'antd-mobile'
import { addRoute, DynamicPages, setBreadcrumbs } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import AuthUtil from 'util/auth'


import './admin.css'

import {
    AppOutline,
    MessageOutline,
    MessageFill,
    CompassOutline,
    AppstoreOutline,
    UnorderedListOutline,
    UserOutline,
} from 'antd-mobile-icons';

function App() {




    const tabs = [
        {
            key: 'project',
            title: '找工程',
            icon: <div className={"paifu-icon project-icon"}></div>,
            //icon: <img src={require("../asserts/icon/project.png")} style={{ width: 30 }} />,
            badge: Badge.dot,
        },
        {
            key: 'worker',
            title: '找工人',
            icon: <div className={"paifu-icon worker-icon"}></div>,
            //icon: <AppstoreOutline />,
            badge: '5',
        },
        {
            key: 'information',
            title: '行业资讯',
            icon: <div className={"paifu-icon information-icon"}></div>,
            // icon: <MessageFill />,
            badge: '99+',
        },
        {
            key: 'factory',
            title: '找厂家',
            icon: <div className={"paifu-icon factory-icon"}></div>,
            // icon: <CompassOutline />,
            badge: '99+',
        },
        {
            key: 'personal',
            title: '个人中心',
            icon: <div className={"paifu-icon personal-icon"}></div>,
            // icon: <UserOutline />,
        },
    ]


    const navigate = useNavigate();
    const location = useLocation();

    const [activeKey, setActiveKey] = useState();

    useEffect(() => {
        const firstLocation = (location.pathname.match(/\/(\S*)\//) || location.pathname.match(/\/(\S*)/));
        if (firstLocation != null) {
            setActiveKey(firstLocation[1])
        }
    }, [location.pathname])

    return (
        <>
            <div style={{ marginBottom: 40 }}>
                <DynamicPages />
            </div>

            <div className="fixed-bottom">
                <TabBar onChange={(key) => {
                    if (key == 'personal') {
                        post(module.auth + "/auth/v1.0/check/login", {
                        }).then(res => {
                            if (res.data) {
                                navigate(key);
                                setActiveKey(key);
                            } else {
                                AuthUtil.goLogin("/personal");
                            }
                        })
                    } else {
                        navigate(key);
                        setActiveKey(key);
                    }

                }} activeKey={activeKey}>
                    {tabs.map(item => (
                        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                    ))}
                </TabBar>
            </div>

        </>
    );
}
addRoute("*", App);
export default App;
