import React, { useState, useEffect, useContext } from 'react'
import { Button, Space } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import AuthUtil from 'util/auth'
import { LoginContext } from 'util/context';

function Page() {

    const navigate = useNavigate();
    const hasLogin = useContext(LoginContext);

    return (
        <div style={{ position: 'fixed', bottom: 60, width: '100%', textAlign: 'center' }}>
            <Space >
                <Button size='middle' color='primary' onClick={() => {
                    if (hasLogin) {
                        navigate("/project/form");
                    } else {
                        AuthUtil.goLogin("/project/form");
                    }

                }}>免费发布工程</Button>
                <Button size='middle' color='success'
                    onClick={() => {
                        if (hasLogin) {
                            navigate("/worker/form");
                        } else {
                            AuthUtil.goLogin("/worker/form");
                        }
                    }}>免费发布找活</Button>
            </Space>
        </div>
    );
}
export default Page;
