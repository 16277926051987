import React, { useState, useEffect, useContext } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dropdown, Radio, Space } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import AuthUtil from 'util/auth'
import regionData from 'util/region';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import CascaderTreeView from 'component/CascaderTreeView'
import NewBtns from 'pages/new-btns';
import moment from 'moment'
import { LoginContext } from 'util/context';


import './detail';

function Page() {


    const navigate = useNavigate();
    const hasLogin = useContext(LoginContext);

    const [param, setParam] = useState({
        activeKey: null,
        region: [],
        regionTitle: "地区",
        projectType: [],
        projectTypeTitle: "工程类别",
        hasMore: true,
        current: 0,
        size: 10
    })

    const [projectParam, setProjectParam] = useState({
        metaCode: "paifuFactory",
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }],
        params: [{
            attrCode: "status",
            operator: "=",
            value: "online"
        }]
    });
    const [projectData, setProjectData] = useState({
        records: []
    });

    const orderProject = (order) => {
        const orders = order == "default" ? [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }] : [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "collectCount",
            order: "desc"
        }];
        setProjectParam({
            ...projectParam, orders: orders
        });
    }

    const reloadProject = (newParam) => {
        const searchParams = [];
        searchParams.push({
            attrCode: "status",
            operator: "=",
            value: "online"
        });
        if (newParam.region.length > 0) {
            searchParams.push({
                attrCode: "fullRegionCode",
                operator: "like",
                value: newParam.region.join(",") + "%"
            });
        }
        setProjectParam({
            ...projectParam, params: searchParams
        });
    }

    const callFactory = (id) => {
        if (hasLogin) {
            post('/api/hr/paifu/factory/contact/call', {
                id: id
            }).then(res => {
                window.location.href = "tel://" + res.data;
            })
        } else {
            AuthUtil.goLogin();
        }
    }


    useEffect(() => {


    }, [])

    return (
        <>
            <div className="dropdown-head">
                <Dropdown activeKey={param.activeKey} onChange={(key) => {
                    setParam({ ...param, activeKey: key })
                }}>
                    <Dropdown.Item key='region' title={param.regionTitle}>
                        <CascaderTreeView
                            style={{ '--height': '320px' }}
                            url={module.app + '/public/region/tree'}
                            initData={regionData}
                            value={param.region}
                            onChange={(val, extend) => {
                                var regionTitle = (val.length > 0) ? extend.items[extend.items.length - 1].label : "地区";
                                const newParam = { ...param, region: val, regionTitle: regionTitle };
                                setParam(newParam);
                            }}
                            onConfirm={() => {
                                const newParam = { ...param, activeKey: null };
                                setParam(newParam)
                                reloadProject(newParam);
                            }}
                            onCancel={() => {
                                const newParam = { ...param, region: [], regionTitle: "地区", activeKey: null };
                                setParam(newParam);
                                reloadProject(newParam);
                            }}
                        />
                    </Dropdown.Item>

                    <Dropdown.Item key='more' title='排序'>
                        <div style={{ padding: 12 }}>
                            <Radio.Group defaultValue='default'
                                onChange={(v) => {
                                    const newParam = { ...param, activeKey: null };
                                    setParam(newParam)
                                    orderProject(v);
                                }}>
                                <Space direction='vertical' block>
                                    <Radio block value='default' >
                                        <span className='font-14'>最新发布</span>
                                    </Radio>
                                    {/* <Radio block value='nearest'>
                                        <span className='font-14'>距离最近</span>
                                    </Radio> */}
                                    <Radio block value='topCollect'>
                                        <span className='font-14'>收藏最多</span>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </Dropdown.Item>
                </Dropdown>

            </div>


            <ScrollList className="dropdown-body"
                url={module.app + '/public/meta/data/search'}
                param={projectParam}
                setParam={setProjectParam}
                data={projectData}
                setData={setProjectData}
            >
                {
                    projectData.records.map(project => (
                        <List.Item
                            key={project.id}
                            onClick={() => {
                                navigate("/factory/detail?id=" + project.id);
                            }}
                            style={{ marginTop: 5 }}
                        >
                            <div>
                                <Ellipsis direction='end' rows={2} content={project.name} />
                            </div>
                            <div style={{ marginTop: 5 }}>
                                <img src={project.img} style={{ maxWidth: '100%' }} />
                            </div>
                            <div className="flex-row-center flex-justify-between font-12 mt-10">
                                <span className='flex-row-center'><LocationFill />
                                    <Ellipsis direction='end' content={project.fullRegionName}></Ellipsis>
                                </span>
                                <div>
                                    <Button size='mini' color='primary' style={{ marginLeft: 5 }} onClick={() => {
                                        callFactory(project.id);
                                    }}>
                                        <PhoneFill /> 联系厂家
                                    </Button>
                                </div>
                            </div>
                        </List.Item>
                    ))
                }

            </ScrollList>
            <NewBtns />
        </>
    );
}
addPage("/factory", Page);
export default Page;
