import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import CascaderSelect from 'component/CascaderSelect';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Dialog, Toast, Image, Button, Tag, Form, Input, TextArea } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Page() {


    let query = useQuery();
    const metaCode = "paifuProject";

    const [pageForm] = Form.useForm()


    const navigate = useNavigate();

    const [param, setParam] = useState({
        step: 1
    })


    const submitStep = () => {
        pageForm.validateFields().then((values) => {
            post(module.auth + "/auth/v1.0/password/reset", values)
                .then(res => {
                    Toast.show({
                        content: '操作成功',
                    })
                    navigate(-1);
                })
        })
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>修改密码</NavBar>
            <Form
                form={pageForm}
                footer={
                    <>
                        <Button block type='submit' color='primary' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name='password' label="旧密码"
                        rules={[{ required: true, message: '请输入旧密码' }]}>
                        <Input type={"password"} />
                    </Form.Item>
                    <Form.Item name='newPassword' label="新密码"
                        rules={[
                            { required: true, message: '请输入密码' },
                            {
                                validator: (_, value) => {
                                    return !(new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")).test(value) ?
                                        Promise.reject('至少6个字符数字或者特殊字符') : Promise.resolve();
                                }
                            }
                        ]}>
                        <Input type={"password"} />
                    </Form.Item>
                    <Form.Item name='confirmPassword' label="确认密码"
                        rules={[
                            { required: true, message: '请再次输入密码' },
                            {
                                validator: (_, value) => {
                                    return value != pageForm.getFieldValue("newPassword") ? Promise.reject('两次密码不一致') : Promise.resolve();
                                }
                            },
                        ]}>
                        <Input type={"password"} />
                    </Form.Item>
                </>
            </Form>
        </>
    );
}
addRoute("/personal/password/reset", Page);
export default Page;
