import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dialog, Modal, NavBar, Toast, SpinLoading } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import { getMetaLabel } from 'util/meta';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}



function Page() {

    const metaCode = "paifuWorker";

    const navigate = useNavigate();

    const [param, setParam] = useState({
        metaCode: metaCode
    });

    const query = useQuery();



    useEffect(() => {
        post("/api/weixin/js/config", {
            url: window.location.href
        }).then(res => {
            window.wx.config({
                debug: false,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'showAllNonBaseMenuItem', 'showMenuItems'],
                ...res.data
            })
        })
    }, [])

    const createOrder = (produtCode) => {
        post("/api/ec/public/order/create", {
            description: "积分购买",
            orderProducts: [{
                productCode: produtCode,
                number: 1
            }]
        }).then(res => {
            createPayment(res.data.code)
        })
    }

    const createPayment = (orderCode) => {
        post("/api/ec/weixin/payment/create", {
            orderCode: orderCode
        }).then(res => {
            const responseData = JSON.parse(res.data.responseData);
            responseData.timestamp = responseData.timeStamp;
            responseData.success = function (res) {
                Modal.confirm({
                    content: '支付完成，前往查看积分。',
                    okText: '查看',
                    cancelText: '取消',
                    onConfirm: function () {
                        navigate("/personal/points");
                    }
                });
            }
            console.log("responseData", responseData)
            window.wx.chooseWXPay(responseData);
        })
    }

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>积分充值</NavBar>

            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>30积分充值</h2>
                <div style={{ marginBottom: 10 }}>积分充值30积分30元</div>
                <Button block color='primary' onClick={() => {
                    createOrder("a5d7e4029e284fe48064969d52b02f83");
                }} >￥30购买</Button>
            </div>

            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>120积分充值</h2>
                <div style={{ marginBottom: 10 }}>积分充值120积分100元</div>
                <Button block color='primary' onClick={() => {
                    createOrder("52f522d8bcac4beb882b1d2e23cdb36e");
                }} >￥100购买</Button>
            </div>

            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>800积分充值</h2>
                <div style={{ marginBottom: 10 }}>积分充值800积分500元</div>
                <Button block color='primary' onClick={() => {
                    createOrder("7bd613e0fae9444e91e05aa84c3b2d6e");
                }} >￥500购买</Button>
            </div>

            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>2000积分充值</h2>
                <div style={{ marginBottom: 10 }}>积分充值2000积分1000元</div>
                <Button block color='primary' onClick={() => {
                    createOrder("be265c2608574560a45956793672e445");
                }} >￥1000购买</Button>
            </div>

        </>
    );
}
addPage("/personal/points/buy", Page);
export default Page;
