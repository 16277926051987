import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import { ResultPage } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {
    const navigate = useNavigate();
    let query = useQuery();
    let points = query.get("points");
    if (points == null) {
        points = "10";
    }
    console.log()
    return (
        <ResultPage
            status='success'
            title='签到成功'
            description={'签到成功+' + points + '积分'}
            secondaryButtonText='返回个人中心'
            onSecondaryButtonClick={() => {
                navigate("/personal")
            }}
        />
    );
}
addRoute("/personal/sign/result", Page);
export default Page;
