import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Dialog, Tabs, NavBar, ImageUploader, Toast } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate } from 'react-router-dom';
import DictPickerItem from 'component/DictPickerItem'
import DatePickerItem from 'component/DatePickerItem'
import moment from 'moment';

function Page() {


    const navigate = useNavigate();

    const [account, setAccount] = useState({
        attributes: {}
    });

    const logout = () => {
        post(module.auth + '/auth/v1.0/logout', {
        }).then(res => {
            Toast.show({
                content: '成功退出',
            })
            navigate("/project");
        })
    }

    const loadAccountInfo = () => {
        post(module.ac + '/my/account/info', {
        }).then(res => {
            setAccount(res.data);
        })
    }
    useEffect(() => {
        loadAccountInfo();
    }, [])



    const updateAccountAttr = (metaCode, v) => {
        post(module.ac + '/my/account/attr/save', {
            metaCode: metaCode,
            metaValue: v
        }).then(res => {
            loadAccountInfo();
            Toast.show({
                content: '更新成功',
            })
        })
    }


    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>修改信息</NavBar>

            <List header=''>
                <List.Item extra={<Image
                    src={account.attributes.headimg}
                    style={{ borderRadius: 20 }}
                    fit='cover'
                    width={40}
                    height={40}
                />}
                    onClick={() => {
                        navigate("/personal/edit/headimg")
                    }}
                >头像</List.Item>

                <List.Item extra={<span>{account.name}</span>} onClick={() => {
                    navigate("/personal/edit/name")
                }}>
                    姓名
                </List.Item>


                <DictPickerItem dictCode="GENDER" value={account.attributes.gender} onChange={(v) => {
                    updateAccountAttr('gender', v);
                }} >
                    性别
                </DictPickerItem>

                <DatePickerItem value={account.attributes.birthdate}
                    min={moment("1955-01-01", 'YYYY-MM-dd').toDate()}
                    max={new Date()} onChange={(v) => {
                        updateAccountAttr('birthdate', v);
                    }}>
                    出生日期
                </DatePickerItem>
                <List.Item extra={<span>{account.phone}</span>} onClick={() => {
                    navigate("/personal/edit/phone")
                }}>
                    手机号
                </List.Item>

                <List.Item extra={'修改密码'} onClick={() => {
                    navigate("/personal/password/reset")
                }}>
                    密码
                </List.Item>

            </List>
            <div className="pd-20">
                <Button color='danger' onClick={() => { logout() }} block>退出登录</Button>
            </div>

        </>
    );
}
addPage("/personal/edit", Page);
export default Page;
