import React, { useState, useEffect, useContext } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import StringTag from 'component/StringTag';
import { post } from 'util/http';
import module from 'util/module';
import AuthUtil from 'util/auth'
import { NavBar, Space, Toast, Image, Button, Tag, Card, Modal } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { HeartOutline, PhoneFill, SendOutline, HeartFill } from 'antd-mobile-icons'
import moment from 'moment'
import { LoginContext } from 'util/context';
import { getMetaLabel } from 'util/meta';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {

    const navigate = useNavigate();
    let query = useQuery();
    const [collectId, setCollectId] = useState(null);

    const [project, setProject] = useState({});

    const [creator, setCreator] = useState({});


    const hasLogin = useContext(LoginContext);

    useEffect(() => {


        post('/api/hr/paifu/my/worker/creator/get', {
            id: query.get("id")
        }).then(res => {
            setCreator(res.data);
        })

        post(module.app + '/public/meta/data/get', {
            metaCode: "paifuWorker",
            id: query.get("id")
        }).then(res => {
            setProject(res.data);
        })

        if (hasLogin) {
            post(module.app + '/my/meta/data/search', {
                metaCode: "paifuCollection",
                params: [{
                    attrCode: "targetCode",
                    operator: "=",
                    value: query.get("id")
                }, {
                    attrCode: "type",
                    operator: "=",
                    value: 'worker'
                }]
            }).then(res => {
                if (res.data.total > 0) {
                    setCollectId(res.data.records[0].id)
                }
            })
        }



    }, [])


    const cancelCollect = () => {
        const url = module.app + '/my/meta/data/delete';
        post(url, {
            metaCode: "paifuCollection",
            id: collectId
        }).then(res => {
            Toast.show({
                content: '取消成功',
            })
            setCollectId(null);
        })
    }

    const collectProject = () => {
        const url = module.app + '/my/meta/data/save';
        post(url, {
            metaCode: "paifuCollection",
            datas: {
                type: "worker",
                targetCode: query.get("id"),
                targetTitle: project.name + "   " + project.intro
            }
        }).then(res => {
            Toast.show({
                content: '收藏成功',
            })
            setCollectId(res.data);
        })
    }

    const confirmCall = () => {
        if (hasLogin) {
            post('/api/hr/paifu/my/worker/creator/call', {
                id: query.get("id")
            }).then(res => {
                if (res.status == -1) {
                    Modal.confirm({
                        content: '您当前的积分不足，只需1积分即可查看电话.是否前往获取积分',
                        okText: '获取积分',
                        cancelText: '取消',
                        onConfirm: function () {
                            navigate("/personal/points/obtain");
                        }
                    });
                } else {
                    window.location.href = "tel://" + res.data;
                }
            })
        } else {
            AuthUtil.goLogin();
        }
    }
    const callWorkerCreator = () => {
        Modal.confirm({
            content: '仅需消耗1积分查看电话,确认？',
            okText: '确认',
            cancelText: '取消',
            onConfirm: function () {
                confirmCall();
            }
        });
    }


    return (
        <>
            <NavBar onBack={() => {
                navigate(-1)
            }}>工人详情</NavBar>
            <div className="pd-10-20">
                <Card
                    style={{
                        border: '1px solid #998899',
                    }}
                >
                    <div className="flex-row-center ">
                        <div style={{ width: 60 }}>
                            <Image
                                src={project.headImage}
                                style={{ borderRadius: 20 }}
                                fit='cover'
                                width={40}
                                height={40}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ lineHeight: "35px" }}>
                                <h3 style={{ display: 'inline' }}>{project.name}</h3>
                                <span style={{ marginLeft: '10px' }}>
                                    {getMetaLabel('GENDER', project.gender)}
                                </span>
                                <span style={{ marginLeft: '10px' }}>
                                    {project.birthdate != null && moment(new Date()).diff(project.birthdate, 'years') + '岁'}
                                </span>
                            </div>
                            <Space style={{ lineHeight: "35px" }}>
                                {/* <Tag color='success'> 已实名</Tag> */}

                                <Tag round color='#2db7f5'> {project.typeName} </Tag>
                            </Space>
                        </div>
                    </div>
                    <div className="flex-row-center flex-justify-between mt-10">
                        <div>联系电话：{creator.phone}</div>
                        <div>
                            <Button size='mini' color='primary' style={{ marginLeft: 5 }} onClick={() => {
                                callWorkerCreator();
                            }}>
                                <PhoneFill /> 免费拨打
                            </Button>
                        </div>
                    </div>
                </Card>
                <div>

                </div>
                <h2>求职期望</h2>
                <div style={{ lineHeight: '26px' }}>
                    <div className='font-15'><span className='font-bold'>工作地：</span>{project.fullRegionName}</div>
                    <div className='font-15'><span className='font-bold'>工资：</span>{project.salary}</div>
                    <div className="font-15" style={{ lineHeight: '30px', whiteSpace: 'pre-wrap' }}>
                        {project.intro}
                    </div>
                </div>
            </div>

            <div className="fixed-bottom ">
                <div className="flex-row-center flex-justify-between pd-10">
                    <div>
                        {collectId == null && <span className='font-18 mr-10'
                            onClick={collectProject}><HeartOutline /> 收藏</span>}
                        {collectId != null && <span className='font-18 mr-10 color-red'
                            onClick={cancelCollect}><HeartFill /> 收藏</span>}
                        {/* <span className='font-18'><SendOutline /> 分享</span> */}
                    </div>

                    <Button size='middle' color='primary' style={{ marginLeft: 5 }} onClick={() => {
                        callWorkerCreator();
                    }}>
                        <PhoneFill /> 免费拨打
                    </Button>
                </div>
            </div>
        </>
    );
}
addRoute("/worker/detail", Page);
export default Page;
