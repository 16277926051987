import React, { useState, useEffect, useContext } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import StringTag from 'component/StringTag';
import { post } from 'util/http';
import module from 'util/module';
import AuthUtil from 'util/auth'
import { NavBar, Space, Toast, Image, Button, Modal } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { HeartOutline, PhoneFill, SendOutline, HeartFill } from 'antd-mobile-icons'
import moment from 'moment'
import { LoginContext } from 'util/context';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {

    const navigate = useNavigate();
    let query = useQuery();
    const hasLogin = useContext(LoginContext);

    const [project, setProject] = useState({
        status: 'finish'
    });
    const [creator, setCreator] = useState({});

    const [collectId, setCollectId] = useState(null);

    useEffect(() => {

        post('/api/hr/paifu/my/project/creator/get', {
            id: query.get("id")
        }).then(res => {
            setCreator(res.data);
        })

        post(module.app + '/public/meta/data/get', {
            metaCode: "paifuProject",
            id: query.get("id")
        }).then(res => {
            setProject(res.data);
        })

        if (hasLogin) {
            post(module.app + '/my/meta/data/search', {
                metaCode: "paifuCollection",
                params: [{
                    attrCode: "targetCode",
                    operator: "=",
                    value: query.get("id")
                }, {
                    attrCode: "type",
                    operator: "=",
                    value: 'project'
                }]
            }).then(res => {
                if (res.data.total > 0) {
                    setCollectId(res.data.records[0].id)
                }
            })
        }


    }, [])


    const cancelCollect = () => {
        const url = module.app + '/my/meta/data/delete';
        post(url, {
            metaCode: "paifuCollection",
            id: collectId
        }).then(res => {
            Toast.show({
                content: '取消成功',
            })
            setCollectId(null);
        })
    }

    const collectProject = () => {
        const url = module.app + '/my/meta/data/save';
        post(url, {
            metaCode: "paifuCollection",
            datas: {
                type: "project",
                targetCode: query.get("id"),
                targetTitle: project.title
            }
        }).then(res => {
            Toast.show({
                content: '收藏成功',
            })
            setCollectId(res.data);
        })
    }

    const confirmCall = () => {
        if (hasLogin) {
            post('/api/hr/paifu/my/project/creator/call', {
                id: query.get("id")
            }).then(res => {
                if (res.status == -1) {
                    Modal.confirm({
                        content: '您当前的积分不足，只需10积分即可查看电话.是否前往获取积分',
                        okText: '获取积分',
                        cancelText: '取消',
                        onConfirm: function () {
                            navigate("/personal/points/obtain");
                        }
                    });

                } else {
                    window.location.href = "tel://" + res.data;
                }
            })
        } else {
            AuthUtil.goLogin();
        }
    }
    const callProjectCreator = () => {
        Modal.confirm({
            content: '仅需消耗10积分查看电话,确认？',
            okText: '确认',
            cancelText: '取消',
            onConfirm: function () {
                confirmCall();
            }
        });
    }


    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>工程详情</NavBar>
            <div className="pd-10-20">
                <div className="font-16 font-bold">{project.title}</div>
                <div className="font-12 c-gray pd-tb-15" >发布时间：
                    {moment(project.createTime).format('YYYY-MM-DD HH:mm')}</div>
                <div className="flex-row-center flex-justify-between">
                    <div className="flex-row-center">
                        <Image
                            src={project.creatorHeadImg}
                            style={{ borderRadius: 20 }}
                            fit='cover'
                            width={40}
                            height={40}
                        />
                        <div style={{ marginLeft: 20 }}>
                            <div className='lh-26'> {project.creatorName}</div>
                            <span >{creator.phone}</span>
                        </div>
                    </div>

                    {project.status != 'finish' && <Button size='mini' color='primary' onClick={() => { callProjectCreator() }}>
                        <PhoneFill /> 联系老板
                    </Button>}
                </div>
                <div className="font-14 font-bold pd-tb-20">工程描述</div>
                <div className="lh-26 font-14" dangerouslySetInnerHTML={{
                    __html: project.content
                }}>
                </div>
                <div className="font-14 font-bold pd-tb-15">关键词</div>
                <div >
                    <StringTag data={project.tags} color="warning"></StringTag>
                </div>
                <div className="t2 font-14 pd-tb-15 font-bold" >工程地址</div>
                <div className="font-14 c-gray">
                    {project.fullRegionName}<br />
                    {project.address}
                </div>
            </div>

            <div className="fixed-bottom ">
                <div className="flex-row-center flex-justify-between pd-10">
                    <div>
                        {collectId == null && <span className='font-18 mr-10'
                            onClick={collectProject}><HeartOutline /> 收藏</span>}
                        {collectId != null && <span className='font-18 mr-10 color-red'
                            onClick={cancelCollect}><HeartFill /> 收藏</span>}

                        {/* <span className='font-18'><SendOutline /> 分享</span> */}
                    </div>

                    {project.status != 'finish' && <Button size='middle' color='primary' style={{ marginLeft: 5 }} onClick={() => {
                        callProjectCreator();
                    }}>
                        <PhoneFill /> 联系老板
                    </Button>}
                </div>
            </div>
        </>
    );
}
addRoute("/project/detail", Page);
export default Page;
