import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dropdown, Radio, Tag, Space } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import regionData from 'util/region';
import ScrollList from 'component/ScrollList'
import CascaderTreeView from 'component/CascaderTreeView'
import StringTag from 'component/StringTag'
import NewBtns from 'pages/new-btns';
import moment from 'moment'

import './detail';
import './form';

function Page() {


    const navigate = useNavigate();


    const [param, setParam] = useState({
        activeKey: null,
        region: [],
        regionTitle: "地区",
        projectType: [],
        projectTypeTitle: "工种类别",
        hasMore: true,
        current: 0,
        size: 10
    })

    const [projectParam, setProjectParam] = useState({
        metaCode: "paifuWorker",
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }],
        params: [{
            attrCode: "status",
            operator: "=",
            value: "ongoing"
        }]
    });
    const [projectData, setProjectData] = useState({
        records: []
    });

    const orderProject = (order) => {
        const orders = order == "default" ? [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }] : [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "collectCount",
            order: "desc"
        }];
        setProjectParam({
            ...projectParam, orders: orders
        });
    }

    const reloadProject = (newParam) => {
        const searchParams = [];
        searchParams.push({
            attrCode: "status",
            operator: "=",
            value: "ongoing"
        });
        if (newParam.region.length > 0) {
            searchParams.push({
                attrCode: "fullRegionCode",
                operator: "like",
                value: newParam.region.join(",") + "%"
            });
        }
        if (newParam.projectType.length > 0) {
            searchParams.push({
                attrCode: "fullTypeCode",
                operator: "like",
                value: newParam.projectType.join(",") + "%"
            });
        }
        setProjectParam({
            ...projectParam, params: searchParams
        });
    }



    useEffect(() => {


    }, [])

    return (
        <>
            <div className="dropdown-head" >
                <Dropdown activeKey={param.activeKey} onChange={(key) => {
                    setParam({ ...param, activeKey: key })
                }}>
                    <Dropdown.Item key='region' title={param.regionTitle}>
                        <CascaderTreeView
                            style={{ '--height': '320px' }}
                            initData={regionData}
                            url={module.app + '/public/region/tree'}
                            value={param.region}
                            onChange={(val, extend) => {
                                var regionTitle = (val.length > 0) ? extend.items[extend.items.length - 1].label : "地区";
                                const newParam = { ...param, region: val, regionTitle: regionTitle };
                                setParam(newParam);
                            }}
                            onConfirm={() => {
                                const newParam = { ...param, activeKey: null };
                                setParam(newParam)
                                reloadProject(newParam);
                            }}
                            onCancel={() => {
                                const newParam = { ...param, region: [], regionTitle: "地区", activeKey: null };
                                setParam(newParam);
                                reloadProject(newParam);
                            }}
                        />
                    </Dropdown.Item>
                    <Dropdown.Item key='projectType' title={param.projectTypeTitle}>
                        <CascaderTreeView
                            style={{ '--height': '320px' }}
                            url={module.app + '/public/meta/data/tree'}
                            param={{
                                metaCode: "paifuWorkType",
                                showRoot: false
                            }}
                            value={param.projectType}
                            onChange={(val, extend) => {
                                var projectTypeTitle = (val.length > 0) ? extend.items[extend.items.length - 1].label : "工种类别";
                                setParam({ ...param, projectType: val, projectTypeTitle: projectTypeTitle });
                            }}
                            onConfirm={() => {
                                const newParam = { ...param, activeKey: null };
                                setParam(newParam)
                                reloadProject(newParam);
                            }}
                            onCancel={() => {
                                const newParam = { ...param, projectType: [], projectTypeTitle: "工程类别", activeKey: null };
                                setParam(newParam);
                                reloadProject(newParam);
                            }}
                        />
                    </Dropdown.Item>
                    <Dropdown.Item key='more' title='更多筛选'>
                        <div style={{ padding: 12 }}>
                            <Radio.Group defaultValue='default'
                                onChange={(v) => {
                                    const newParam = { ...param, activeKey: null };
                                    setParam(newParam)
                                    orderProject(v);
                                }}>
                                <Space direction='vertical' block>
                                    <Radio block value='default' >
                                        <span className='font-14'>最新发布</span>
                                    </Radio>
                                    {/* <Radio block value='nearest'>
                                        <span className='font-14'>距离最近</span>
                                    </Radio> */}
                                    <Radio block value='topCollect'>
                                        <span className='font-14'>收藏最多</span>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </Dropdown.Item>
                </Dropdown>

            </div>


            <ScrollList className="dropdown-body worker"
                url={module.app + '/public/meta/data/search'}
                param={projectParam}
                setParam={setProjectParam}
                data={projectData}
                setData={setProjectData}
            >
                {
                    projectData.records.map(project => (
                        <List.Item
                            arrow={false}
                            key={project.id}
                            onClick={() => {
                                navigate("/worker/detail?id=" + project.id);
                            }}
                        >
                            {project.showOrder == 2147483647 && <img src={require('../../asserts/images/zd.png')} className="zd" />}
                            <div className="flex-row-center">
                                <div style={{ width: 60 }}>
                                    <Image
                                        src={project.headImage}
                                        style={{ borderRadius: 20 }}
                                        fit='cover'
                                        width={40}
                                        height={40}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div>

                                        <span className="mr-10 font18">{project.name}</span>
                                        <span className='mr-10 font-14 c-gray'>
                                            {project.gender == 'male' ? '男' : "女"}
                                        </span>
                                        {project.birthdate != null && <span className='mr-10 font-14 c-gray'>
                                            {moment(new Date()).diff(project.birthdate, 'years')}岁
                                        </span>}
                                        {/* <span className='font-14 c-gray' style={{ float: 'right' }}>30分钟前在线</span> */}
                                    </div>
                                    <Space>
                                        {/* <Tag color='success'> 已实名</Tag> */}
                                        <Tag round color='#2db7f5'> {project.typeName} </Tag>
                                    </Space>
                                </div>
                            </div>
                            <div className="lh-26 c-gray font-14 ">
                                <div>期望工作地：{project.fullRegionName}</div>
                                <div>期望工工资：{project.salary}</div>
                                <div>
                                    <StringTag data={project.tags}
                                        //  style={{ backgroundColor: "#b4b3b3", borderColor: "#b4b3b3" }} 
                                        color={"warning"}
                                    ></StringTag>
                                </div>
                                {/* <div style={{ whiteSpace: 'pre-wrap' }}>
                                    {project.intro}
                                </div> */}
                            </div>

                        </List.Item>
                    ))
                }

            </ScrollList>
            <NewBtns />
        </>
    );
}
addPage("/worker", Page);
export default Page;
