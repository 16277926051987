import React, { useState, useEffect, useContext } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import StringTag from 'component/StringTag';
import { post } from 'util/http';
import module from 'util/module';
import AuthUtil from 'util/auth'
import { NavBar, Space, Toast, Image, Button, Tag } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { HeartOutline, PhoneFill, SendOutline } from 'antd-mobile-icons'
import moment from 'moment'
import { LoginContext } from 'util/context';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {

    const navigate = useNavigate();
    let query = useQuery();

    const hasLogin = useContext(LoginContext);

    const [project, setProject] = useState({});
    const [contactPhone, setContactPhnoe] = useState("");

    useEffect(() => {

        post(module.app + '/public/meta/data/get', {
            metaCode: "paifuFactory",
            id: query.get("id")
        }).then(res => {
            setProject(res.data);
        })

        // post('/api/hr/paifu/factory/contact-phone/get', {
        //     id: query.get("id")
        // }).then(res => {
        //     setContactPhnoe(res.data)
        // })
    }, [])

    // const callFactory = () => {
    //     if (hasLogin) {
    //         post('/api/hr/paifu/factory/contact/call', {
    //             id: query.get("id")
    //         }).then(res => {
    //             window.location.href = "tel://" + res.data;
    //         })
    //     } else {
    //         AuthUtil.goLogin();
    //         // navigate("/login");
    //     }
    // }

    const callFactory = () => {
        window.location.href = "tel://" + project.contactPhone;
    }

    const back = () => {
        navigate("/factory");
    }

    return (
        <>
            <NavBar onBack={back}>厂家详情</NavBar>
            <div className="pd-10-20">
                <div>
                    <Image
                        src={project.bannerImg}
                        width={"100%"}
                    />
                </div>
                <div className="font-20 pd-tb-15">{project.name}</div>
                <div >
                    服务内容：{project.serviceContent}
                </div>
                <div className='line-hr'></div>
                <div className="font-16 "> 详细介绍:</div>
                <div dangerouslySetInnerHTML={{
                    __html: project.content
                }}>
                </div>
                <div className='line-hr'></div>
                <div className="font-14 ">
                    <label className="font-bold ">联系人：</label>{project.contactName}
                </div>
                <div className='line-hr'></div>
                <div className="font-14 ">
                    <label className="font-bold ">联系电话：</label>
                    {project.contactPhone}
                    <Button className="ml-10" size='mini' color='primary' onClick={() => {
                        callFactory();
                    }}>
                        <PhoneFill /> 立即联系
                    </Button>
                </div>
                <div className='line-hr'></div>
                <div className="font-14 ">
                    <label className="font-bold ">联系地址：</label>
                    {project.fullRegionName} {project.address}
                </div>
                <div className='line-hr'></div>
                {/* <div className="flex-row-center flex-justify-between">

                    <div className="flex-row-center">
                        <div >
                            <div className="lh-26">{project.contactName}</div>
                            <div>{contactPhone}</div>
                        </div>
                    </div>

                </div> */}

                {/* <div className="t2 pd-tb-15">工厂地址</div>
                <div className="font-12 c-gray">
                    {project.fullRegionName} {project.address}
                </div> */}
            </div>

            {/* <div className="fixed-bottom ">
                <div className="flex-row-center flex-justify-between pd-10">
                    <div>
                        <span className='font-18 mr-10'><HeartOutline /> 收藏</span>
                        <span className='font-18'><SendOutline /> 分享</span>
                    </div>

                    <Button size='middle' color='primary' style={{ marginLeft: 5 }} onClick={() => {
                        callFactory();
                    }}>
                        <PhoneFill /> 联系工厂
                    </Button>
                </div>
            </div> */}
        </>
    );
}
addRoute("/factory/detail", Page);
export default Page;
