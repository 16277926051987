import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Space, Toast, Image, Button, Tag, Form, Input, Grid } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import CaptchaButton from 'component/CaptchaButton';

function Page() {

    const [pageForm] = Form.useForm()

    const navigate = useNavigate();

    const submitLogin = () => {
        pageForm.validateFields().then((values) => {
            post(module.auth + "/auth/v1.0/login/by-phone", values)
                .then(res => {
                    Toast.show({
                        content: '登录成功',
                    })
                    navigate("/");
                })
        })
    }

    return (
        <>
            <NavBar
                back="首页"
                backArrow={false}
                onBack={() => { navigate("/project") }}>登录</NavBar>
            <Form
                form={pageForm}
                layout='horizontal'
                footer={
                    <>

                        <Button block type='submit' color='primary' size='middle' onClick={submitLogin} style={{ marginTop: 10 }}>
                            登录
                        </Button>
                        <Grid columns={2} gap={8}>
                            <Grid.Item>
                                <Button block type='submit' color='default' size='middle' onClick={() => {
                                    navigate("/register")
                                }} style={{ marginTop: 10 }}>
                                    注册
                                </Button>
                            </Grid.Item>
                            <Grid.Item>
                                <Button block type='submit' color='default' size='middle' onClick={() => {
                                    navigate("/login")
                                }} style={{ marginTop: 10 }}>
                                    账号登录
                                </Button>
                            </Grid.Item>
                        </Grid>

                    </>
                }
            >

                <Form.Item name='phone' label="手机号" rules={[
                    { required: true, message: '请输入手机号' },
                    {
                        validator: (_, value) => {
                            return !(/^1[3-9]\d{9}$/).test(value) ? Promise.reject('手机号格式不正确') : Promise.resolve();
                        }
                    },
                ]}>
                    <Input placeholder='请输入手机号' />
                </Form.Item>
                <Form.Item name='smsCode' label="短信验证码"
                    rules={[{ required: true, message: '请输入短信验证码' }]}
                    extra={<CaptchaButton fill='none' size="small" business="login" getPhone={() => {
                        return pageForm.validateFields(['phone']).then(res => {
                            return res.phone;
                        }, err => {
                            return null;
                        })
                    }}></CaptchaButton>} >
                    <Input placeholder='请输入验证码' />
                </Form.Item>
            </Form>
        </>
    );
}
addRoute("/login/phone", Page);
export default Page;
